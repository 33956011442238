import React, { Component } from "react";
import "./ForgotPassword.scss";
import { Container, withStyles, TextField, Typography, Avatar, Button } from "@material-ui/core/";
import strings from "../../../lang/l10n.js";
import { LockSharp } from "@material-ui/icons";
import APIService from "../../../services/APIService.js";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  bg: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
  marginTop: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: 0,
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      success: false,
      errors: []
    };
  }

  onFormSubmit = async () => {
    try {
      const response = await APIService.requestPasswordRenewal(this.state.email);

      if (response?.status === 'ok') {
        this.setState({success: true});
      }
    } catch (e) {

    }
  }

  render = () => {
    const { classes } = this.props;
    return (
      <Container id="mainview" maxWidth="xs">
        <div className={classes.bg}>
          <Avatar className={classes.avatar}>
            <LockSharp />
          </Avatar>
          <Typography component="h1" variant="h5">
            POB Tool
          </Typography>

          {this.state.success ? (
            <div style={{textAlign: "center", marginTop: 20}}>
              <Typography variant="body1">
                If the given email is attached to an account, a password renewal link will be sent to your email.
              </Typography>
            </div>
          ) : (
            <form
              className={classes.form}
              noValidate
              onSubmit={(ev) => { ev.preventDefault(); this.onFormSubmit(); }}
            >
              <Typography variant="body1">
                Enter the email address associated with your account
              </Typography>

              <TextField
                error={this.state.error}
                className={classes.textField}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={strings.email}
                name="email"
                type="email"
                autoFocus
                onChange={(ev) => this.setState({email: ev.target.value})}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
              >
                {strings.continue}
              </Button>
            </form>
          )}
          <Link
            component={Button}
            to="/login">
              Back to Login
          </Link>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
