import { DeleteData, PostData, PutData, GetData } from "./RequestService.js";
import LocalStorageService from "./LocalStorageService.js";

class APIService {
  static catchResult = (result) => {
    // Exception --> message
    if (!result || result.error || result.errors || result.exception) {
      return {
        error: result.error || result.errors || result.message || "error",
      };
    }

    if (result.message === "Unauthenticated.") {
      LocalStorageService.logout();
    }

    return result;
  };

  static catchError = (error) => {
    console.log(error);
  };

  static getBrand = async () => {
    return GetData("brand")
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static getOffer = async (id) => {
    return GetData("offers/" + id)
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static deleteOffer = async (id) => {
    return DeleteData("offers/" + id)
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static updateOffer = async (id, data) => {
    return PutData("offers/" + id, data)
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static downloadOffer = async (id) => {
    return GetData("offers/" + id + "/download")
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static createOffer = async (data) => {
    return PostData("offers/", data)
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static getOffers = async (search = null) => {
    return GetData("offers")
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static getCountries = async () => {
    return GetData("countries")
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static search = async (searchQuery, market) => {
    return GetData("search?query=" + searchQuery + "&market=" + market)
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static updateSettings = async (data) => {
    return PutData("users/settings/", data)
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  };

  static requestPasswordRenewal = async (email) => {
    return PostData("users/renew-password", {email})
      .then(APIService.catchResult)
      .catch(APIService.catchError);
  }
}

export default APIService;
