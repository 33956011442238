import LocalizedStrings from "react-localization";
const en = require("./en.json");
const de = require("./de.json");

let strings = new LocalizedStrings({
  en,
  de,
});

export default strings;
